// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-card-js": () => import("./../../../src/pages/card.js" /* webpackChunkName: "component---src-pages-card-js" */),
  "component---src-pages-card-with-image-js": () => import("./../../../src/pages/card-with-image.js" /* webpackChunkName: "component---src-pages-card-with-image-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

